.ekko-lightbox {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-right: 0px!important;

  .ekko-lightbox-container {
    position:relative;
    > div.ekko-lightbox-item {
      position:absolute;
      top:0;
      left:0;
      bottom:0;
      right:0;
      width:100%;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .ekko-lightbox-nav-overlay {
    z-index:100;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display: flex;

    a {
      flex: 1;
      display:flex;
      align-items: center;

      opacity: 0;
      transition: opacity 0.5s;
      color: #fff;
      font-size:30px;
      z-index:100;

      > * {
        flex-grow:1;
        &:focus {
          outline: none;
        }
      }
      span {
        padding:0 30px;
      }
      &:last-child span {
        text-align: right;
      }
      &:hover {
        text-decoration: none;
      }
      &:focus {
        outline: none;
      }
      &.disabled {
        cursor: default;
        visibility: hidden;
      }
    }
  }

  a:hover {
    opacity: 1;
    text-decoration: none;
  }
  .modal-dialog {
    display:none;
  }
  .modal-footer {
    text-align: left;
  }

  // http://tobiasahlin.com/spinkit/
  .ekko-lightbox-loader {
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    width:100%;

    display: flex;           /* establish flex container */
    flex-direction: column;  /* make main axis vertical */
    justify-content: center; /* center items vertically, in this case */
    align-items: center;

    > div {
      width: 40px;
      height: 40px;

      position: relative;
      text-align: center;

      > div {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #fff;
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;

        animation: sk-bounce 2.0s infinite ease-in-out;
        &:last-child {
          animation-delay: -1.0s;
        }
      }
    }
  }

  .modal-dialog .ekko-lightbox-loader {
    > div > div {
      background-color:#333;
    }
  }

  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bounce {
    0%, 100% {
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
      }
  }
}
