/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    outline: none;
    padding: 0 $grid-gutter-width * 0.5;
    
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
        width: 100%;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}


/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;

    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
    }
    &.slick-disabled::before {
        opacity: 0.3;
    }

    &::before {
        content: '';
        font-size: 20px;
        line-height: 1;
        color: $gray-600;
        opacity: 1;
        width: 20px;
        height: 20px;
        display: block;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}


.slick-prev {
    left: -30px;

    @include media-breakpoint-up(xl){
        left: -20px - $grid-gutter-width;
    }
    
    &::before {
        background: url(../layout/arrow-left.svg) no-repeat center;
    }

    &:hover::before {
        background: url(../layout/arrow-left_hover.svg) no-repeat center;
    }
}

.slick-next {
    right: -30px;

    @include media-breakpoint-up(xl){
        right: -20px - $grid-gutter-width;
    }

    &::before {
        background: url(../layout/arrow-right.svg) no-repeat center;
    }

    &:hover::before {
        background: url(../layout/arrow-right_hover.svg) no-repeat center;
    }
}


/****** Dots *********/

.slick-dots {
    position: absolute;
    bottom: -2.25 * $spacer;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0 !important;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        width: 13px;
        height: 13px;
        margin: 0.2 * $spacer 0.5 * $spacer !important;
        padding: 0;
        cursor: pointer;

        &::before {
            display: none !important;
        }
        
        button {
            border: 0;
            background: transparent;
            display: block;
            width: 13px;
            height: 13px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;

            &:hover, &:focus {
                outline: none;
                &::before {
                    opacity: 0.5;
                }
            }

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: 13px;
                height: 13px;
                border-radius: 8px;
                background: $gray-500;
                opacity: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        &.slick-active button::before {
            background: $primary;
            opacity: 1;
        }

        &:first-child:last-child {
            display: none;
        }
    }
}


.slider-nav {
    position: relative;
    height: 30px;

    .slick-prev {
        right: 4 * $spacer + 1.5 * $spacer;
        left: auto;
        &:before {
            color: $gray-400;
        }
    }

    .slick-next {
        right: $spacer;
        &:before {
            color: $gray-400;
        }
    }
}

/********** GALLERY **************/

.gallery.slider {
	margin-left: -0.5 * $grid-gutter-width;
	margin-right: -0.5 * $grid-gutter-width;

    .slick-dotted.slick-slider {
        //margin-bottom: 3 * $spacer + 30px;
    }
}
