/*!
 * Theme mit Bootstrap v4.1.3 (https://getbootstrap.com)
 * Copyright LemonHead
 */

/* cardo-regular - latin */
@font-face {
  font-family: 'Cardo';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/cardo-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Cardo'), local('Cardo-Regular'),
       url('../fonts/cardo-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cardo-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cardo-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cardo-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cardo-v9-latin-regular.svg#Cardo') format('svg'); /* Legacy iOS */
}

/* source-serif-pro-regular - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/source-serif-pro-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Source Serif Pro'), local('SourceSerifPro-Regular'),
       url('../fonts/source-serif-pro-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-serif-pro-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-serif-pro-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-serif-pro-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-serif-pro-v5-latin-regular.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
}


/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/source-sans-pro-v11-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
       url('../fonts/source-sans-pro-v11-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v11-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v11-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v11-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/source-sans-pro-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
       url('../fonts/source-sans-pro-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v11-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/source-sans-pro-v11-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
       url('../fonts/source-sans-pro-v11-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v11-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v11-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v11-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v11-latin-italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/source-sans-pro-v11-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
       url('../fonts/source-sans-pro-v11-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v11-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v11-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v11-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v11-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}


/**********************************************  Color vars  ******************************************/

$gray-100: #f8f8f8 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #AFAFAF !default;
$gray-600: #8C8C8C !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #222222 !default;

$primary: 		#2662AB;
$primary-dark:	#00388C;
$secondary:		#FCDC19;
$secondary-light: lighten($secondary,41%);
$success:		#5cb85c;
$info:			#5bc0de;
$warning:		#f0ad4e;
$danger:		#d9534f;



/** Overwrite Bootstrap defaults **/
$theme-colors: (
  "primary": 	$primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
);

$body-bg: #ffffff;
$body-color: #000000;

/***********************************************  Link vars  ******************************************/
$link-color: $primary;
$link-hover-color: $body-color;

$border-radius: 0;
$border-radius-sm:  0;


/************************************************  Modal vars  ******************************************/

$modal-inner-padding:               1rem 3rem;

$modal-dialog-margin:               0rem;
$modal-dialog-margin-y-sm-up:       3rem;

$modal-content-border-width:        0px;
$modal-content-border-radius:       0px;

$modal-backdrop-opacity:            0.75;
$modal-header-border-width:         0px;
$modal-footer-border-width:         0;
$modal-header-padding:              1rem 3rem;

/***********************************************  Grid vars  ******************************************/

$grid-gutter-width: 24px;

/**********************************************  Nav vars  *******************************************/

$navbar-dark-color:                $body-bg;
$navbar-dark-hover-color:          $body-bg;
$navbar-dark-active-color:         $gray-900;

$navbar-toggler-padding-y: .5rem;
$navbar-toggler-padding-x: 1rem;
$navbar-toggler-font-size: 1.1rem;

$navbar-nav-link-padding-x: 1rem;
$nav-link-padding-y: 0.4rem;
$navbar-padding-x: 0;
$navbar-padding-y: 0;

$dropdown-min-width: 11.5rem;
$dropdown-bg: $primary;
$dropdown-border-color: $primary;
$dropdown-spacer: 0;

// Breadcrumbs

$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding:           0;

$breadcrumb-margin-bottom:          2rem;

$breadcrumb-bg:                     white;
$breadcrumb-divider-color:          $gray-600;
$breadcrumb-active-color:           $gray-600;

$breadcrumb-border-radius:          0;


/**********************************************  Form vars  ******************************************/
$btn-font-weight: 800;
$btn-padding-x: 1rem;
$btn-focus-box-shadow: none;
$input-btn-focus-box-shadow: none;

$input-placeholder-color: $gray-600;
$input-focus-box-shadow: none;
$input-bg: lighten($secondary,44%);

/**********************************************  Font vars  ******************************************/

$font-family-base: 		'Source Sans Pro', sans-serif;
$font-weight-bold:      600;
$font-size-base: 		1rem;
$line-height-base: 		1.4;

$lead-font-size:		1.15 * $font-size-base;

$headings-font-family: 'Cardo', serif;
$headings-color: $primary;
$headings-line-height: 1.25;
$headings-font-weight: 600;

$h1-font-size:	$font-size-base * 1.28;
$h2-font-size:  $font-size-base * 1.28;
$h3-font-size:  $font-size-base * 1.15;


$paragraph-margin-bottom: 1.75rem;


/**********************************************  Card vars  ******************************************/
$card-columns-count: 2;
$card-border-width: 0;
$card-spacer-x: 0;

/**********************************************  Bootstrap imports  **************************************/

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root"; //brings error
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/media";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/print";

@import "slick";
@import "ekko-lightbox";


/************************************************************************************************************/
/**************							General Styles and Customizations 						*************/
/************************************************************************************************************/


/******************* Headings **********************************/

h1, .h1 {
	margin-bottom: 2 * $spacer;
	@extend .seperator;
}

h2, .h2, h3, .h3, h4, .h4 {
	font-family: $font-family-base;
}

h2, .h2 {
	font-weight: $font-weight-light;
}

h4, .h4, h5, .h5, h6, .h6 {
	font-size: $font-size-base;
}

h4.seperator {
	color: $gray-500;
	font-weight: $font-weight-light;
	font-size: $h1-font-size;
}


/***************** type **********************************/

.seperator {
	&::after {
		content: '';
		margin-top: 4px;
		display: block;
		height: 4px;
		width: 100%;
		max-width: 700px;
		@include gradient-x($start-color: lighten($primary,40%), $end-color: lighten($secondary,30%));
	}
}

.text-meta {
	color: $gray-500;
	font-size: $lead-font-size;
	font-weight: $font-weight-light;
}

p.lead {
	margin-bottom: 1.2 * $spacer;
}


/******************** Lists **********************************/

#content ul {
	list-style: none;
	padding-left: 0;
	margin: 0.4 * $spacer 0;

	& > li {
		padding-left: $spacer;
		margin-bottom: 0.5 * $spacer;
		position: relative;

		&::before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0.5 * $spacer;
			width: 0.3 * $spacer;
			height: 0.3 * $spacer;
			border-radius: 0.2 * $spacer;
			background-color: $gray-500;
		}
	}

	ul li {
		margin-bottom: 0;
		&::before {
			display: none;
			padding-left: 0;
		}
	}

	&.primary li::before {
		background-color: $primary;
	}

	&.secondary  li::before {
		background-color: $secondary;
	}
}

#content h4 + ul,
#content .link-list ul {
	& > li {
		padding-left: 0;
		&::before {
			display: none;
		}
	}
}

#content h4 + ul {

	@include media-breakpoint-up(md) {
		margin-left: 10%;
	}

	@include media-breakpoint-up(lg) {
		margin-left: 25.6%;
	}
}

#content .link-list ul > li {
	margin-bottom: 0;
}


/***************** Buttons **********************************/

.btn {
	line-height: 1.3;

	&.btn-link {

		&.arrow-left {
			position: relative;
			&::before {
				content: "";
				display: block;
				position: absolute;
				top: 0.65 * $spacer;
				width: 0;
				height: 0;
			    border: 0px solid transparent;

				left: -0.5 * $spacer;
				border-width: 0.3 * $spacer 0.5 * $spacer;
				border-right-color: $gray-500;
			}
		}

		&.arrow-right,
		&.intern {
			position: relative;
			padding-left: 0;

			&::after {
				content: "";
				display: block;
				position: absolute;
				top: 0.65 * $spacer;
				width: 0;
				height: 0;
			    border: 0px solid transparent;
			    
				right: -0.5 * $spacer;
				border-width: 0.3 * $spacer 0.5 * $spacer;
				border-left-color: $gray-500;
			}
		}

		&.intern::after {
			top: 0.4 * $spacer;
		}

		&.pdf {
			position: relative;
			padding-right: 2 * $spacer;
			padding-left: 0;
			
			&::after {
				content: "PDF";
				display: block;
				background: $gray-500;
				color: $white;
				font-size: 0.7 * $font-size-base;
				padding: 0.1 * $spacer 0.2 * $spacer 0;
				position: absolute;
				top: 0.5 * $spacer;
				right: 0;
				border-radius: 2px;
				line-height: 0.9rem;
				font-weight: $font-weight-light;
			}
		}

		&.download {
			position: relative;
			padding-right: 1.25 * $spacer;
			padding-left: 0;

			&::after {
				content: "";
				display: block;
				position: absolute;
				top: 0.75 * $spacer;
				width: 0;
				height: 0;
			    border: 0px solid transparent;
			    
				right: 0 * $spacer;
				border-width: 0.5 * $spacer 0.3 * $spacer;
				border-top-color: $gray-500;
			}
		}
	}
}

/********************* Icons **********************************/

.icon-newsletter {
	position: relative;
	padding-left: 2.5 * $spacer;

	&::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 2 * $spacer;
		height: 2 * $spacer;
		background: url(../layout/bottle.svg) no-repeat left center;
		background-size: contain;
	}

	&.btn {
		padding-left: 3 * $spacer;
		text-align: left;

		&::before {
			left: 0.5 * $spacer;
			top: 0.25 * $spacer;
		}
	} 
}

.icon-facebook {
	position: relative;
	padding-left: 2.5 * $spacer;

	&::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 2 * $spacer;
		height: 2 * $spacer;
		background: url(../layout/facebook-c.svg) no-repeat left center;
		background-size: 1.7 * $spacer;
	}

	&.btn {
		padding-left: 3 * $spacer;
		text-align: left;
		
		&::before {
			left: 0.5 * $spacer;
			top: 0.25 * $spacer;
		}
	} 
}

.icon-star {
	&::after {
		content: "";
		display: block;
		float: right;
		width: 1.2 * $spacer;
		height: 1.2 * $spacer;
		background: url(../layout/star_yellow.svg) no-repeat left center;
		background-size: contain;
		margin-top: 0.25 * $spacer;
		margin-left: 90%;
	}
}

.entry.simple .entry-meta.icon-star {
	position: relative;
	padding-left: 1.7 * $spacer;
	&::after {
		position: absolute;
		top: 0;
		left: 0;
		margin-top: 0;
		float: none;
		margin-left: 0;
	}
}

.icon-logged {
	&::before {
		content: "";
		display: inline-block;
		width: $font-size-base;
		height: $font-size-base;
		background: url(../layout/lock.svg) no-repeat left center;
		background-size: contain;
		margin-right: 0.2 * $spacer;
	}

	&.nav-link::before {
		background-image: url(../layout/lock_white.svg);
	}
}

.icon-unlogged {
	&::before {
		content: "";
		display: inline-block;
		width: $font-size-base;
		height: $font-size-base;
		background: no-repeat left center;
		background-size: contain;
		margin-right: 0.2 * $spacer;
	}

	&.nav-link::before {
		background-image: url(../layout/lock-open_white.svg);
	}
}



/***************** Header & NAV **********************************/

#logo {
    background-image: url(../layout/syc_logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 140px;
    height: 70px;
    margin-left: 0.5 * $grid-gutter-width;
    z-index: 1;

    @include media-breakpoint-up(md) {
    	margin-left: 2.5 * $grid-gutter-width;
    }

    @include media-breakpoint-up(lg) {
    	position: absolute;
    	left: 135px;
    	top: -90px;
    	margin-left: 0;
    	width: 180px;
    	height: 90px;
    }

    @include media-breakpoint-up(xl) {
    	left: 16.67%;
    	top: auto;
      width: 210px;
      height: 108px;
    }
}

#header {

	.navbar {
		&.navbar-fake {
			z-index: 10;
			height: $nav-link-height * 1.05;
			margin-top: 1 * $spacer;
			box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3);

			@include media-breakpoint-up(lg){
				margin-top: 0;
				z-index: 1;
			}
		}

		&.fixed-top {
			top: 0.5 * $spacer;

			@include media-breakpoint-up(lg){
				top: 6 * $spacer;
			}

			@include media-breakpoint-up(xl){
				top: 6 * $spacer;
			}
		}

		#main-navbar {
			@include media-breakpoint-down(md){
				background: $primary;
				margin-top: -1.1 * $spacer;
				padding-top: 1.5 * $spacer;
				text-align: left;
				font-size: 1.1em;
				max-height: 80vh;
				overflow-y: auto;
			}

			.dropdown-menu {
				@include media-breakpoint-down(md){
					text-align: left;
					padding-left: 2rem;
				}
			}
		}

		.navbar-nav {
			line-height: 1.5;

			& > .nav-item {
				font-family: $headings-font-family;
				margin: 0 1px;
				position: relative;

				& > .nav-link {
					background: $primary;
					display: inline-block; 

					@include media-breakpoint-down(md){
						padding-left: 10px;
						padding-right: 10px;
						min-height: 3rem; /* same height as dropdown-toggle */
						padding-top: 0.75rem; /* adjust vertical text alignment */
					}
				}

				.extra-dropdown-toggle {
					cursor: pointer;
					position: absolute; 
					background-color: $primary;
					top: 0; 
					color: white; 
					font-weight: bold; 
					font-size: 28px; 
					line-height: 1.3em;
					right: 0.4rem; 
					width: 3rem; 
					height: 3rem; 
					text-align: center; 
					display: none;

					&::after {
						content: "+";
						display: block;
    					padding-top: 0.25rem;
					}

					@include media-breakpoint-up(sm) {
				    margin-right: 2 * $grid-gutter-width;
				  }

				  @include media-breakpoint-up(lg) {
				    margin-right: 0;
				    height: 2.3rem;
				    width: 2.3rem;
				    right: 0;
				  }

					&.menu-open {
						&::after {
							content: "-";
						}
					}
				}

				&.show > .nav-link, 
				&.active > .nav-link, 
				.nav-link.show, 
				.nav-link.active {
					background: $secondary;
				}
			}
		}

		.nav-item {
			&.selected {
				background: $secondary;
			}
		}
		
		.dropdown-menu {
			font-family: $font-family-base;
		}

		.navbar-toggler {
			border-color: transparent;
			.navbar-toggler-icon {
				background-color: $primary;
				color: $white;
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='3' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
			}

			&.collapsed .navbar-toggler-icon {
				color: $body-color;
				background-color: transparent;
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='3' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
			}

			@include media-breakpoint-up(md) {
		    margin-right: 2 * $grid-gutter-width;
		  }
		}
	}

	.pic-container {
		position: relative;
		z-index: 10;
		margin-top: 3.8 * $spacer;
		margin-left: -0.5 * $grid-gutter-width;
		margin-right: -0.5 * $grid-gutter-width;

		@include media-breakpoint-up(md) {
			margin: 0;
		}

		.head-title {
			position: absolute;
			bottom: 0;
			right: 0;
			color: $white;
			font-size: $h1-font-size * 1.3;
			font-weight: $font-weight-light;
			text-align: right;
			padding: 0.5 * $spacer $spacer;
			z-index: 1;
			line-height: 1.2;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
			width: 100%;
			height: 100%;
			
		}
	}

	#lang-nav {
		position: absolute;
		top: -2.1 * $spacer;
		right: 3.5 * $spacer;
		z-index: 1000;
		font-size: $font-size-sm;

		@include media-breakpoint-up(md){
			top: 1.75 * $spacer;
			right: 6.5 * $spacer;
		}

		@include media-breakpoint-up(lg){
			top: $spacer;
			right: $grid-gutter-width * 0.5;
		}		

		a {
			@extend .nav-link;
			padding-bottom: 0.3 * $spacer;
			background: $secondary;
			text-transform: uppercase;
		}

		li {
			margin-right: 1px;
			&#de:first-child:last-child {
				display: none;
			}

			&.active a {
				text-decoration: underline;
				font-weight: $font-weight-bold
			}
		}
	}
}

.use-touch #header .navbar .navbar-nav {
	.nav-item.dropdown {
		@include media-breakpoint-up(lg){
			padding-right: 2.2rem;
		}

		& > .extra-dropdown-toggle {
			display: block
		}
		
		& > .nav-link::after {
			display: none;
		}
	} 
}

.regatta #header .pic-container {
	margin-bottom: 2 * $spacer;
	&::after {
		@include gradient-y(transparent, rgba(38, 100, 171, 0.8), 50%, 100%);
	}
}

.member #header .pic-container {
	margin-bottom: 2 * $spacer;
	&::after {
		@include gradient-y(rgba(38, 100, 171, 0.7),rgba(252, 218, 25, 0.5), 0%, 50%);
	}
}

.breadcrumb-row {
	color: $gray-500;
	font-size: $font-size-sm;
	margin-top: 0.25 * $spacer;
	text-align: right;

	ol.breadcrumb {
		display: inline-block;

		li {
			display: inline-block;

			a {
				color: $gray-500;
			}

			&::after {
				content: '/';
				padding: 0 0.25 * $spacer;
			}

			&:last-child::after {
				display: none;
			}
		}
	}
}

/******************** Content **********************************/

.container-fluid {
	position: relative;

	@media (min-width: 1440px) {
    	max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
    }
}

#content {

	.row.grid {
		display: block;
		@extend .clearfix;

		& > .col {
			@include media-breakpoint-up(md){
				float: left;
				flex: unset;
			}
		}
	}

	.content-block {
		margin-bottom: 1.5 * $spacer; 

		&:first-child {
			h1 {
				max-width: 75%;
			}
		}

		h2:first-child {
			@extend .seperator;
			position: relative;
			padding-top: 1.5 * $spacer;

			&::after {
				position: absolute;
				top: 0;
			}
		}

		figure.float-right {
			max-width: 50%;
			margin-left: 0.5 * $grid-gutter-width;
			padding-left: 0.5 * $grid-gutter-width;
		}
	}
}

.content-block table {
	margin-bottom: 1rem;
	th, td {
		padding: 0.25rem 0.5rem;
		vertical-align: top;
		border: 1px solid $gray-300;
	}
}

.event-list {

	margin-bottom: 4 * $spacer;

	.row {
		margin-top: 0.5 * $spacer;
		margin-bottom: 0.5 * $spacer;
	}

	.month-title {
		color: $gray-500;
		font-weight: $font-weight-light;
		font-size: $h1-font-size;
		text-indent: 0.25 * $spacer;

		&::after {
			content: '';
			@extend .seperator;
			height: 2px;
		}
	}

	.entry-meta {
		text-align: center;
		.time {
			display: inline-block;
			&::before {
				content: '-';
				padding-left: 0.25 * $spacer;
				padding-right: 0.25 * $spacer;
			}
		}
		@include media-breakpoint-up(sm){
			text-align: right;
			.time {
				display: block;
				&::before {
					display: none;
				}
			}
		}
	}

	.veranstaltung .date {
		background: lighten($secondary, 33);
		color: $gray-600;
		padding-left: 0.25 * $spacer;
		padding-right: 0.25 * $spacer;
	}

	.regatta .date {
		background: lighten($primary, 33);
		color: white;
		padding-left: 0.25 * $spacer;
		padding-right: 0.25 * $spacer;
	}

	.entry-content {
		border-bottom: 1px solid $border-color;
		padding-bottom: $spacer;

		.entry-teaser {
			font-weight: $font-weight-light;
		}

		p {
			margin: 0;
		}
	}
}

/****************** Pagination ***********************************/

.pagination {
	margin-top: 2 * $spacer;
	position: relative;
	@extend .seperator;
	&::after {
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.btn.btn-link {
		color: $gray-500;
		font-size: $h2-font-size;
		font-weight: $font-weight-light;

		&.arrow-left::before,
		&.arrow-right::after {
			border-top-width: 0.45 * $spacer;
			border-bottom-width: 0.45 * $spacer;
		}
	}
}


/***************** Card-Columns **********************************/

.card .card-img-top {
	margin-bottom: $card-spacer-y;
}

.card .card-img-bottom {
	margin-top: $card-spacer-y;
}

.card.contact {
	margin-bottom: 2 * $spacer;
	@include media-breakpoint-up(lg){
		margin-bottom: 3 * $spacer;
	}
}

.card-columns {
	column-count: 1;

	@include media-breakpoint-up(sm){
		column-count: 2;
	}
	
	&.columns-3 {
		@include media-breakpoint-up(md){
			column-count: 3;
		}
	}

	&.columns-4 {
		@include media-breakpoint-up(lg){
			column-count: 4;
		}
	}
}

/******************** Images ***********************************/

.content-block p img {
	&.float-right {
		width: 100%;
		@include media-breakpoint-up(sm){
			width: 33.33%;
	    	margin-left: $grid-gutter-width;
	    }
	}
}


/******************** Gallery **********************************/

.gallery {
	&.grid {
		@extend .card-columns;
		.item {
			@extend .card;
			display: inline-block;
			width: 100%;
			margin-bottom: $grid-gutter-width;
			border: none;
		}
	}
}

// Content: Teaser
// ----------

.box {
	&.box-full-img-left {
		position: relative;
		padding-left: 52% !important;

		figure {
		  position: absolute;
		  left: 0;
		  top: 0;
		  width: 48%;
		  height: 100%;
		  overflow: hidden;

		  img {
		    opacity: 0;
		  }
		}
	}

	&.box-img-left figure{
		float: left;
		text-align: left;
		width: 48%;
		margin-right: 4%;
		margin-bottom: 0;
	}

	&.box-full-img-right {
		position: relative;
		padding-right: 52% !important;

		figure {
		  text-align: right;
		  position: absolute;
		  right: 0;
		  top: 0;
		  width: 48%;
		  height: 100%;
		  overflow: hidden;

		  img {
		    opacity: 0;
		  }
		}
	}

	&.box-img-right figure{
		float: right;
		text-align: right;
		width: 48%;
		margin-left: 4%;
		margin-bottom: 0;
	}

	figure {
		width: 100%;
		background-size: cover;
		background-position: center 70%;
	}

	&.advert {
  		figcaption {
  			color: $gray-500;
  			text-align: right;
  		}
  	}

  	&.contact {
  		@extend .clearfix;
  		.text.float-left {
			width: 50%;
			padding-right: 0.5 * $grid-gutter-width;
		}

		img.float-right {
			max-width: 50%;
			padding-left: 0.5 * $grid-gutter-width;
		}
  	}
}

#main {
	.entry-meta {
		color: $gray-500;
		font-weight: $font-weight-light;
	}

	.col-lg-8 h1 {
		@include media-breakpoint-up(lg){
			max-width: 74.5%;
		}
	}

	.box {
		position: relative;
		margin-bottom: 2 * $spacer;

		.box-title {
			margin: 0 0 $spacer;
			font-family: $headings-font-family;
			color: $gray-500;
			font-weight: $font-weight-base;
			font-size: $lead-font-size;
		}

		&.advert .box-title {
  			margin-bottom: 0.2 * $spacer;
  		}

		&.list-teaser .box-title {
			font-size: $h1-font-size;
			margin-bottom: 0;
		}

		&.box-primary {
			background: $primary;
			color: white;
			padding: 1.5 * $spacer 1 * $spacer;

			.box-title {
			  color: white;
			}
		}

		&.box-light {
			padding: 0.75 * $spacer 0;
			margin: 0.25 * $spacer 0 2.25 * $spacer;

			&:after, &:before {
			  content: "";
			  display: block;
			  width: 100%;
			  height: 0.25 * $spacer;
			  @include gradient-x($start-color: lighten($primary,40%), $end-color: lighten($secondary,30%));
			  position: absolute;
			  left: 0;
			}

			&:before {
			  top: -0.25 * $spacer;
			}

			&:after {
			  bottom: -0.25 * $spacer
			}

			.box-title {
				color: $primary;
			}
		}
	}

	.list-teaser {
		.entry {
			position: relative;
			margin-top: 0.5 * $spacer;
			padding-left: 4.75 * $spacer;

			&::after {
				content: "";
				display: block;
				border-bottom: 1px solid $border-color;
			}

			.entry-meta {
				position: absolute;
				left: 0;
				width: 4.25 * $spacer;
				text-transform: uppercase;
				font-size: $h1-font-size;
			}

			a, .entry-title {
				color: $body-color;
			}
		}

		&.news .entry {
			margin-top: 1.5 * $spacer;
			margin-bottom: 1.5 * $spacer;

			.entry-meta { 
				font-size: 1.3 * $h1-font-size;
				line-height: 1.1;
			}
		}
	}
}

.list-teaser, .news-list, .content-block {
	p a.btn-link {
		padding-top: 0;
	    padding-bottom: 2px;
	    border: none;
	    line-height: inherit;

	    &.arrow-right{
	    	padding-left: 0;
	    	&::before {
	    		content: '... ';
	    	}
	    	&::after {
	    		top: 0.45 * $spacer;
	    	}
	    }
	}
}

.news-list {
	.entries {
		.entry {
			padding-left: 25.5%;
			position: relative;
			margin-bottom: 2 * $spacer;

			&::after {
				content: "";
				width: 100%;
				display: block;
				border-bottom: 1px solid $border-color;
			}

			.entry-meta {
				position: absolute;
				left: 0;
				top: 0;
				width: 25.5%;
				font-size: $h2-font-size;
				margin-top: -0.25 * $spacer;
				text-align: right;
				padding-right: $grid-gutter-width;
			}
		}
	}
}

.single-entry-page #main {
	.entry-meta {
		margin-bottom: 0.5 * $spacer;

		@include media-breakpoint-up(lg){
			font-size: $h1-font-size * 1.12; //1.4rem
			line-height: 1.5rem;
			position: absolute;
		    left: -7.5 * $spacer;
		    width: 6 * $spacer;
		    text-align: right;
		}
	}
}

/********* Teaserbeareich Secondary *************/

#secondary {
  background: lighten($secondary, 30%);
  margin-top: 3 * $spacer;
  margin-bottom: 3 * $spacer;
  padding-top: 2 * $spacer;
  padding-bottom: 0.5 * $spacer;

  .col {
  	margin-bottom: $grid-gutter-width;
  }

  .box {
  	background: white;
  	padding: $spacer 0.75 * $spacer;

  	.box-title {
  		color: $primary;
  		font-size: $font-size-base * 1.2;
  		margin-bottom: 0.5 * $spacer;

  		.seperator {
  			display: none;
  		}
  	}
  }

  .card .card-body {
  	padding: $spacer 0.75 * $spacer;
  }

  .entry-meta {
  	color: $gray-500;
  }

  .entry-meta + .entry-title::before {
  		content: '|';
  		color: $gray-500;
  		padding-right: 0.25 * $spacer;
  		padding-left: 0.25 * $spacer;
  }

  .list-teaser {
  	.entry {
  		white-space: nowrap;
  		overflow: hidden;
  		text-overflow: ellipsis;

  		.entry-title {
  			display: inline;
  		}
  	}
  	.entry-meta {
  		display: inline-block;
  	}
  	.entry-title {
  		display: inline-block;
  	}
  }
}

.home {
	#secondary {
		padding-top: 0.75 * $spacer;
	}

	.entry-meta .time {
		display: none;
	}
}

/********* Akkordion *************/

.accordion {

	.acc-head {
		margin-bottom: $spacer;
		position: relative;
		padding-right: 3 * $spacer;

		@extend .seperator;

		.h2 {
			color: $gray-500;
		}

		.h2::after {
			content: "";
			display: inline-block;
			width: 0;
			width: 0;
			border: 0px solid transparent;
			border-width: 0.5 * $spacer 0.4 * $spacer;
			border-bottom-color: $gray-500;
			margin-bottom: 0.1 * $spacer;
			margin-left: 0.5 * $spacer;
		} 

		&[aria-expanded="false"] .h2::after {
			border-bottom-color: transparent;
			border-top-color: $gray-500;
			margin-bottom: -0.4 * $spacer;
		}
	}

	.acc-body {

		@include media-breakpoint-up(md) {
			margin-left: 10%;
		}

		@include media-breakpoint-up(lg) {
			margin-left: 25.6%;
		}
	}
}

/***************** FORMs ***********************************/

.contact-form {
    clear: both;
    margin-bottom: 2 * $spacer;

    .feedback {
      background: $secondary-light;
    }
}

form {
	.form-group {
    	position: relative;

    	&.has-error {
    		label{
    			color: $danger;
    		}

    		input, textarea {
    			background: $secondary-light;
    		}
    	}
    }

    #yform-rex_contactrequest-emailbot {
		height: 0;
		overflow: hidden;
	}

	.alert {
    	background: $secondary-light;
    	display: block;

    	ul > li::before {
    		background-color: $danger !important;
    	}
  	}
}

/***************** Footer **********************************/

#footer {
	margin: 3 * $spacer 0;
	.row {
	    margin: 0 0 3 * $spacer 0;

	    &:last-child {
	      font-size: $font-size-sm;
	      color: $gray-600;
	      font-weight: $font-weight-light;
	      line-height: $line-height-base;

	      a {
	        color: $gray-700;
	      }
	    }

	    .btn {
	    	white-space: inherit;
	    }

	    .logos {
	      margin: 0;
	      padding: 0;
		  display: flex;
		  justify-items: flex-start;
		  gap: 1.5 * $spacer;
		  flex-wrap: wrap;
		  align-items: flex-end;

	      img {
	      	max-width: 100%;
			max-height: 4.5 * $spacer;
	      }
	    }
	}

	.footer-nav {
		li {
			&::after {
				content: '|';
				padding: 0 0.5 * $spacer;
				display: inline-block;;
			}

			&:last-child::after {
				display: none;
			}
		}
	}
}
